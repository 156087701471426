<template>
  <section>
    <ValidationObserver ref="obs" v-slot="{ passes }">
      <div class="ct-loading" v-if="containerLoading"></div>
      <section class="setter">
        <div class="img-left">
          <p></p>
          <v-img cover src="https://s3.amazonaws.com/assets.pilsala.com/assets/asian_woman.jpg"></v-img>
        </div>
        <div class="div-rigth">
          <section v-if="!forgotData" class="section-rigth">
            <div>
              <v-img cover :src="dynamicImageUrl" class="max-w-300"></v-img>
            </div>
            <p class="p-text">
              <span>Iniciar Sesión</span>
            </p>
            <section class="d-flex flex-column min-w-250">
              <div class="d-flex">
                <!-- <div>
              <p class="p13">País (Prefijo)</p>
              <country-autocomplete @dialog:change="HandlerCountryResponse" />
            </div> -->
                <!-- <div class="ml-5">
              <p class="p13">Whastapp</p>
              <ValidationProvider
                name="contraseña"
                rules="required"
                v-slot="{ errors }"
              >
                <v-text-field
                  @keyup.enter="passes(HandlerLogin)"
                  type="password"
                  v-model="password"
                  class="mt-md-3 max-width"
                  outlined
                  label="Contraseña"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </div> -->
              </div>
              <div class="d-flex">
                <div>
                  <p class="p13">País (Prefijo)</p>
                  <country-autocomplete
                    @dialog:change="HandlerCountryResponse"
                  />
                </div>
                <div class="ml-5 w-100">
                  <p class="p13">Whastapp</p>
                  <ValidationProvider
                    name="telefono"
                    rules="required|numeric"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="phone_number"
                      class="mt-3 ml-auto"
                      outlined
                      label="Whastapp"
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </div>
              </div>

              <div class="mt--2">
                <p class="p13">Contraseña</p>
                <ValidationProvider
                  name="contraseña"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    @keyup.enter="passes(HandlerLogin)"
                    type="password"
                    v-model="password"
                    class="mt-md-3"
                    outlined
                    label="Contraseña"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </div>

              <p
                class="text-right p15  mt--2 cursor-pointer z-50"
                @click.stop="HandlerForgotPassword({})"
              >
               ¿Olvidaste tu contraseña?
              </p>
              <v-btn
                :disabled="isValid"
                depressed
                class="white--text font-light py-5 text-capitalize mt-5"
                rounded
                color="#101820"
                :loading="loading"
                @click="HandlerLogin"
              >
                Iniciar Sesión
              </v-btn>

           

              <div class="text-center font-light mt-1 mb-2">
                <span>o bien</span>
              </div>


              <v-btn
                color="#f5f5f5"
                rounded
                class="gray--text bg-white py-3 text-none mb-2"
                @click="HandlerRedirectSocialAccount('google')"
                >
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" fill="#4285F4"/><path d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z" fill="#34A853"/><path d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z" fill="#FBBC05"/><path d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z" fill="#EA4335"/><path d="M1 1h22v22H1z" fill="none"/></svg>
                  <span class="ml-2"> Continuar con Google </span>
              </v-btn>

              <v-btn
                color="#f5f5f5"
                rounded
                class="gray--text bg-white py-3 text-none"
                @click="HandlerRedirectSocialAccount('linkedin')"
                >
                  <svg height="24" viewBox="0 0 72 72" width="24" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M8,72 L64,72 C68.418278,72 72,68.418278 72,64 L72,8 C72,3.581722 68.418278,-8.11624501e-16 64,0 L8,0 C3.581722,8.11624501e-16 -5.41083001e-16,3.581722 0,8 L0,64 C5.41083001e-16,68.418278 3.581722,72 8,72 Z" fill="#007EBB"/><path d="M62,62 L51.315625,62 L51.315625,43.8021149 C51.315625,38.8127542 49.4197917,36.0245323 45.4707031,36.0245323 C41.1746094,36.0245323 38.9300781,38.9261103 38.9300781,43.8021149 L38.9300781,62 L28.6333333,62 L28.6333333,27.3333333 L38.9300781,27.3333333 L38.9300781,32.0029283 C38.9300781,32.0029283 42.0260417,26.2742151 49.3825521,26.2742151 C56.7356771,26.2742151 62,30.7644705 62,40.051212 L62,62 Z M16.349349,22.7940133 C12.8420573,22.7940133 10,19.9296567 10,16.3970067 C10,12.8643566 12.8420573,10 16.349349,10 C19.8566406,10 22.6970052,12.8643566 22.6970052,16.3970067 C22.6970052,19.9296567 19.8566406,22.7940133 16.349349,22.7940133 Z M11.0325521,62 L21.769401,62 L21.769401,27.3333333 L11.0325521,27.3333333 L11.0325521,62 Z" fill="#FFF"/></g></svg>
                  <span class="ml-2"> Continuar con LinkedIn </span>
              </v-btn>

              <v-btn
                color=""
                rounded
                depressed
                class="py-5 text-capitalize mt-5 mb-4"
                @click="HandlerRoute('Register')"
              >
                Regístrate
              </v-btn>
            </section>
            <!-- <v-btn
          color="#061F42"
          rounded
          class="white--text font-light"
          @click="HandlerRoute('Register')"
        >
          Regístrate
        </v-btn> -->
          </section>

          <recovery-password
            v-if="forgotData"
            :active="forgotData"
            @dialog:change="HandlerResponse"
          />

        
        </div>
      </section>
    </ValidationObserver>

    <!-- <v-responsive>
      <toolbar-core />
      <v-container>
        <v-row justify="center">
          <v-col cols="12" class="pt-0">
            <v-btn
              @click="HandlerGoBack"
              color="#0CAAC8"
              text
              rounded
              class="text-sub-title text-capitalize mt-5 mt-md-0"
            >
              <v-icon large class="mr-3">mdi-arrow-left-drop-circle</v-icon>
              Volver
            </v-btn>
          </v-col>
          <v-col cols="12" sm="6" md="6" lg="6">
            <ValidationObserver ref="obs" v-slot="{ passes }">
              <v-card class="mb-15 card-custom">
                <div class="py-10 px-10">
                  <v-row>
                    <v-col cols="12">
                      <p class="text-sub-title text-center title-card">
                        Iniciar sesión
                      </p>
                    </v-col>
                    <v-col cols="5" sm="4" md="5" class="pr-0">
                      <country-autocomplete
                        @dialog:change="HandlerCountryResponse"
                      />
                    </v-col>
                    <v-col cols="7" sm="8" md="7" class="pl-0">
                      <ValidationProvider
                        name="telefono"
                        rules="required|numeric"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          v-model="phone_number"
                          class="mt-3 ml-auto"
                          solo
                          prepend-inner-icon="mdi-whatsapp"
                          label="Número de Whatsapp"
                          :error-messages="errors"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12" md="12">
                      <ValidationProvider
                        name="contraseña"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          @keyup.enter="passes(HandlerLogin)"
                          type="password"
                          v-model="password"
                          class="mt-md-3"
                          solo
                          prepend-inner-icon="mdi-lock-outline"
                          label="Contraseña"
                          :error-messages="errors"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12" class="text-center">
                      <v-btn
                        :disabled="isValid"
                        color="#0CAAC8"
                        rounded
                        class="white--text font-light"
                        :loading="loading"
                        @click="passes(HandlerLogin)"
                      >
                        INICIAR SESIÓN
                      </v-btn>
                    </v-col>
                    <v-col cols="12" class="text-center">
                      <v-btn
                        @click.stop="HandlerForgotPassword({})"
                        small
                        text
                        color="#0CAAC8"
                        class="text-capitalize"
                      >
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="d-flex justify-space-between mt-8">
                      <small class="my-auto">¿No tienes cuenta?</small>
                      <v-btn
                        color="#061F42"
                        rounded
                        class="white--text font-light"
                        @click="HandlerRoute('Register')"
                      >
                        Regístrate
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
              </v-card>
            </ValidationObserver>
          </v-col>
        </v-row>
      </v-container>
      <v-snackbar top class="mt-10" timeout="10000" v-model="snackbar">
        {{ text }}

        <template v-slot:action="{ attrs }">
          <v-btn color="#EA5899" text v-bind="attrs" @click="snackbar = false">
            cerrar
          </v-btn>
        </template>
      </v-snackbar>
      <recovery-password
        v-if="forgotData"
        :active="forgotData"
        @dialog:change="HandlerResponse"
      />
    </v-responsive> -->
  </section>
</template>

<script>
import Notification from "../Utils/notification";
// import ToolbarCore from "../core/Toolbar";
import RecoveryPasword from "../AutenticationCode/RecoveryPassword";
import CountryAutocomplete from "@/components/Utils/country_autocomplete";
import { ASSETS_BASE_URL } from "@/constants/config";

export default {
  components: {
    // "toolbar-core": ToolbarCore,
    "recovery-password": RecoveryPasword,
    "country-autocomplete": CountryAutocomplete,
  },

  data: () => ({
    assetsBaseUrl: ASSETS_BASE_URL,
    dynamicImageUrl: null,
    country_id: {},
    phone_number: "",
    password: "",
    loading: false,
    active: false,
    forgotData: false,
    phone_code: "",
    isValid: false,
    snackbar: false,
    text: "",
    data_ref: "",
    containerLoading: true,
    errorMessage: "",
    subdomain: "",
  }),

  created() {
    if (this.$route.query.data_ref != undefined) {
      this.HandlerLoginBack(this.$route.query.data_ref);
    }

    this.subdomain = window.location.hostname.split('.')[0];
    const brand = this.subdomain && this.subdomain !== 'localhost' ? this.subdomain : 'app';
    this.dynamicImageUrl = `https://s3.amazonaws.com/assets.pilsala.com/brand_logos/${brand}.png`;
    this.assetsBaseUrl = ASSETS_BASE_URL;
    console.log('assetsBaseUrl',this.assetsBaseUrl);
  },

  async mounted() {
    this.authToken = this.$route.query.authToken;
    this.errorMessage = this.$route.query.error_message;

    if(this.errorMessage) {
      this.$snotify.error(this.errorMessage, "¡Error!");  
    }

    if(this.authToken) {
      await this.$store.dispatch("auth/TOKEN_VALIDATE",this.authToken);
      await new Promise(resolve => setTimeout(resolve, 1000));
      this.$snotify.success("Cuenta conectada", "¡Bienvenido!");
      this.$router.push({ name: "profile" });
      return; 
    }
  
    this.containerLoading  = false;
  },

  watch: {
    phone_number(code) {
      if (code.length == this.phone_code.length - 1) {
        if (this.phone_code == `+${code}`) {
          this.isValid = true;
          this.$snotify.warning(
            "El codigo del pais ya ha sido seleccionado",
            "Alerta!"
          );
        } else {
          this.isValid = false;
        }
      }
    },
  },

  methods: {
    HandlerRedirectSocialAccount (provider) {
       window.location.href = `https://campus.pilsa.io/auth/${provider}/redirect`;
    },

    async HandlerLogin() {
      try {
        this.loading = true;
        const request = {
          subdomain: this.subdomain,
          country_id: this.country_id.id,
          phone_number: this.phone_number,
          password: this.password,
        };

        await this.$store.dispatch("auth/LOGIN", request);

        this.$snotify.success("Un gusto tenerte de vuelta", "¡Bienvenido!");
        this.$router.push({ name: "profile" });
      } catch (error) {
        if (error.response.status == 422) {
          let e = error.response.data.error;
          let showNotification = new Notification(e);
          showNotification.notification;
        } else if (error.response.status == 401) {
          this.$snotify.error(error.response.data.message, "Error!");
        } else if (error.response.status == 505) {
          this.snackbar = true;
          this.text = error.response.data.message;
        }
      } finally {
        this.loading = false;
      }
    },

    async HandlerLoginBack(id) {
      try {
        this.loading = true;
        const request = {
          data_ref: id,
        };

        await this.$store.dispatch("auth/LOGIN_BACK", request);

        this.$snotify.success("Un gusto tenerte de vuelta", "¡Bienvenido!");
        this.$router.push({ name: "profile" });
      } catch (error) {
        if (error.response.status == 422) {
          let e = error.response.data.error;
          let showNotification = new Notification(e);
          showNotification.notification;
        } else if (error.response.status == 401) {
          this.$snotify.error(error.response.data.message, "Error!");
        } else if (error.response.status == 505) {
          this.snackbar = true;
          this.text = error.response.data.message;
        }
      } finally {
        this.loading = false;
      }
    },

    HandlerGoBack() {
      this.$router.go(-1);
    },

    HandlerRoute(name) {
      this.$router.push({ name: name });
    },

    HandlerForgotPassword() {
      this.forgotData = true;
    },

    HandlerResponse() {
      this.forgotData = false;
    },

    HandlerCountryResponse(val) {
      this.phone_code = val.phone_code;
      this.country_id = val;
    },
  },
};
</script>

<style scoped>
@media (max-width: 1024px) {
  .img-left {
    display: none !important;
  }
  .setter {
    width: 100% !important;
    /* width: 100vw !important; */
    /* background-color: red !important; */
  }
  .div-rigth {
    width: 100% !important;
    padding: 0 1rem !important;
  }
}

.w-100 {
  width: 100%;
}
.p-relative {
  color: #949494;
  font: normal normal 300 13px/20px Poppins;
  position: absolute;
  bottom: 0;
}
.p13 {
  font-size: 13px;
}
.p15 {
  font: normal normal normal 15px/21px Poppins;
  font-size: 15px;
}
.mt--2 {
  margin-top: -10px;
}
.section-rigth {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.p-text {
  margin-top: 0rem;
  font-family: "Poppins", serif;
  font-size: 28px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.setter {
  margin: 0px;
  padding: 0px;
  display: flex;
  height: 100vh;
  justify-content: space-between;
}
.img-left {
  width: 50vw;
  display: flex;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.div-rigth {
  position: relative;
  width: 50vw;
  padding: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: initial;
}
.d-flex {
  display: flex;
}
.max-width {
  max-width: 250px;
}

.ct-loading{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: #ffffff91;
}

.cursor-pointer {
  cursor: pointer;
}

.z-50 {
  z-index: 50 !important;
}


</style>
